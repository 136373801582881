<!-- Modal to display notes associated with a billing batch -->
<template>
  <v-dialog :value="value" persistent width="1000">
    <v-card class="fill" height="800">
      <v-toolbar flat class="accent" color="main" dark>
        <v-toolbar-title>
          {{selectedBatch.name}} Notes
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click.stop="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-container class="pa-4"  style="border:1px solid grey; border-radius:5px; height:500px;overflow-y:auto">
          <v-card v-if='notes.length === 0' class="ma-2 pa-4" outlined>
            <v-card-text class="pa-8">
              <p class="black--text">No Notes Available</p>
            </v-card-text>
          </v-card>
          <v-card v-for="(note, index) in notes" :key="index" class="ma-2 pa-4" outlined>
            <v-card-title class="text-h6">
                {{note.created_by}}
            </v-card-title>
            <v-card-text class="pa-8">
              <p class="black--text">{{note.note_text}}</p>
            </v-card-text>
            <v-card-actions class="card">
                <small>BATCH {{note.note_type}} NOTE</small>
              <v-spacer />
              <small>{{formatDateTime(note.created_on)}}</small>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-container>
    </v-card>
  </v-dialog>
</template>


<script>
import { dateFormat } from '@/mixins/date-format'
import BillingBatch from '@/axios/billing-batch'
import { displayAlert } from '@/mixins/displayAlert'

export default {
  name: 'BatchNotes',

  props: ['value', 'selectedBatch'],
  mixins: [displayAlert, dateFormat],

  async created () {
    this.nonTableHeight = 240
  },

  data () {
    return {
      note: {},
      notes:[],
      selected: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value) this.getBatchNotes()
      }
    }
  },
  methods: {
    async getBatchNotes () {
        this.loading = true
        await this.$BillingBatch.getBatchNotes(this.selectedBatch.id)
        .then(res => {
          this.notes = this._.orderBy(res.data, ['created_on'], ['desc'])
        }).catch(err => {
            this.handleError(err)
    })}
  },
}
</script>
